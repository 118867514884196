import model from './model';

export default model.createController(({ $bind, $widget, flowAPI }) => {
  let refreshCheckout: () => Promise<void> | undefined;

  return {
    async pageReady() {},
    exports: {
      onRefreshCheckout(refreshCheckoutCallback: () => Promise<void>) {
        refreshCheckout = refreshCheckoutCallback;
      },
    },
  };
});
